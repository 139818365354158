'use client';

import { NextUIProvider } from '@nextui-org/system';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { ThemeProviderProps } from 'next-themes/dist/types';
import { useRouter } from 'next/navigation';
import * as React from 'react';
import { ToastContainer } from 'react-toastify';

import ConfirmProvider from '@/providers/ConfirmProvider';

import 'react-toastify/dist/ReactToastify.css';

export interface ProvidersProps {
  children: React.ReactNode;
  themeProps?: ThemeProviderProps;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

export function Providers({ children, themeProps }: ProvidersProps) {
  const router = useRouter();

  return (
    <SessionProvider>
      <NextUIProvider navigate={router.push}>
        <NextThemesProvider {...themeProps}>
          <QueryClientProvider client={queryClient}>
            <ConfirmProvider>
              <React.Suspense>{children}</React.Suspense>
            </ConfirmProvider>

            <ReactQueryDevtools buttonPosition='bottom-left' initialIsOpen={false} />
          </QueryClientProvider>

          <ToastContainer
            closeOnClick
            newestOnTop
            pauseOnHover
            autoClose={2000}
            hideProgressBar={false}
            position='bottom-right'
            theme='light'
          />
        </NextThemesProvider>
      </NextUIProvider>
    </SessionProvider>
  );
}
